











import { Component, Mixins } from 'vue-property-decorator';

import { BlogFilter, ListingFilter, TestimonialFilter } from 'client-website-ts-library/filters';
import {
  Listing, ListingCategory, ListingStatus, Office, Profile, Testimonial, WebsiteLevel, WebsiteProfileDisplayType,
} from 'client-website-ts-library/types';
import { API, Config } from 'client-website-ts-library/services';

import { View } from 'client-website-ts-library/plugins';
import ImageBox from '@/components/ImageBox.vue';
import VideoPlayer from '@/components/VideoPlayer.vue';
import LinkButton from '@/components/UI/LinkButton.vue';
import NavigationLink from '@/components/Common/NavigationLink.vue';

@Component({
  components: {
    ImageBox,
    VideoPlayer,
    LinkButton,
    NavigationLink,
  },
})
export default class Home extends Mixins(View) {
}
